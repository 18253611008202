import React, { useEffect, useRef, useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useLocationContext } from '../../contexts/LocationContextProvider';
import { useAppMediaContext } from '../../contexts/AppMediaContextProvider';

function AddAppMedia({
    showModel,
    setShowModel
}) {
    const [locationName, setLocationName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedImage, setSelectedImage] = useState([]);
    const fileInputRef = useRef(null);
    const { addLocation } = useLocationContext();
    const { allAppMedia,
        getAllAppMedia,
        addAllAppMedia,
        deleteAllAppMedia, } = useAppMediaContext();
    
    const { token } = useAuthContext();

    const handleImageUploadClick = () => {
        fileInputRef.current.click();
      };
    
      const handleImageChange = e => {
        const files = e.target.files;
        setSelectedImage([...files]);
      };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'locationName') {
            setLocationName(value);
        } else if (name === 'latitude') {
            setLatitude(value);
        } else if (name === 'longitude') {
            setLongitude(value);
        }
        setError(''); // Clearing any previous error when user starts typing
    };


    const handleNextButtonClick = async () => {
        setLoading(true);
        if (!selectedImage) {
            setError('Please select a new image.');
            setLoading(false);
            return;
        }
      
        const data = new FormData();
        selectedImage.map((image, index) => {
            data.append("app_media[]",image)
        })

        // Additional actions or state changes can be performed here
        const response = await addAllAppMedia(token, data);
        // Reset input field after processing
        if (!response) {
            setError("Oops! Images not added.please try again.")

        }
        else {
            setShowModel(false);

        }
        setSelectedImage([]);
        setLoading(false);

    };

    const hideError = () => {
        setTimeout(() => {
          setError('');
        }, 5000); // 5000 milliseconds = 5 seconds
      };
    
      // Effect to trigger hiding the error message whenever 'error' state changes
      useEffect(() => {
        if (error) {
          hideError();
        }
      }, [error]);
    

    return (
        <div>{showModel &&
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                    onClick={() => setShowModel(false)}
                    className="fixed inset-0 bg-black opacity-[66%]"
                />
                <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
                    <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                        <div className='flex gap-3 items-center justify-center w-full mb-14'>
                            <h1 className='font-semibold text-2xl text-center'>Add App Media</h1>
                        </div>

                        <div className='flex flex-col items-center gap-10 mt-5'>
                            {/* image */}
                            <div>
                                <div
                                    className={
                                        selectedImage
                                            ? "relative w-max mx-auto "
                                            : "relative w-max mx-auto text-gray-500  rounded-full"
                                    }
                                >
                                    <div className="flex items-center justify-center py-8 bg-gray-300 rounded-full shadow-md px-7">
                                        <img
                                            src={"/images/upload_icon.svg"}
                                            className="text-gray-500 cursor-pointer"
                                            onClick={handleImageUploadClick}
                                        />
                                    </div>
                                </div>
                                {selectedImage.length > 0 ?
                                    <div className="flex items-center justify-center pt-4 text-base font-normal text-gray-500" >{`${selectedImage.length} Images Selected`}</div> :
                                    <div className="flex items-center justify-center pt-4 text-base font-normal " ><p className="text-center"> <span onClick={handleImageUploadClick} className="font-semibold underline cursor-pointer ">Browse Your Visual Here</span>.</p> </div>
                                }
                                <input
                                    type="file"
                                    id="image"
                                    ref={fileInputRef}
                                    accept="image/*"
                                    className="hidden"
                                    multiple={true}
                                    onChange={handleImageChange}
                                />
                            </div>


                            <div className='flex flex-col items-center justify-center w-full gap-2'>
                                {error && <p className='text-red-500'>{error}</p>}
                                <button
                                    onClick={handleNextButtonClick}
                                    className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                                >
                                    {loading
                                        ? <div className="flex items-center justify-center w-full">
                                            <ThreeDots
                                                color="#ffffff"
                                                height={20}
                                                width={40}
                                                className="py-2"
                                            />
                                        </div>
                                        : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AddAppMedia;
