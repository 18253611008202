import React, { useState } from "react";
import { FaUserCircle, FaCog, FaSignOutAlt } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { useAuthContext } from "../contexts/AuthContextProvider";
const Navbar = () => {
  const {userProfile,logout} = useAuthContext();
  const [showProfileOptions, setShowProfileOptions] = useState(false);

  const toggleProfileOptions = () => {
    setShowProfileOptions(!showProfileOptions);
  };

  return (
    <nav className="flex items-center justify-between px-8 py-6 bg-white">
      <div>
        <span className="text-gray-600">Hi</span>
        <span className="ml-1 font-bold text-gray-800">{userProfile?.name}</span>
      </div>
      <div className="flex items-center space-x-8 text-gray-500">
        <a
          href="#"
          onClick={toggleProfileOptions}
          id="profileButton"
          className="relative"
        >
          <img
            className="w-10 h-10 border border-blue-200 rounded-full shadow shadow-gray-300"
            src="/images/navbar/profile.png"
            alt="User Avatar"
          />
          {showProfileOptions &&
            <div className="absolute z-50 right-0 p-2 mt-2 bg-white rounded-lg shadow-md top-12 min-w-[10rem]">
              <ul>
                <li>
                  <a href="#" className="flex items-center  hover:bg-green-100 rounded-md py-1 pl-3 w-full gap-2">
                    <FaUserCircle color="black" />
                    <span className="m-1 text-black">Profile</span>
                  </a>
                </li>
                <li>
                <a href="#" className="flex items-center  hover:bg-green-100 rounded-md py-1 pl-3 w-full gap-2">
                    <AiFillSetting color="black" />
                    <span className="m-1 text-black">Settings</span>
                  </a>
                </li>
                <li>
                <div onClick={()=>logout()} className="flex items-center  hover:bg-green-100 rounded-md py-1 pl-3 w-full gap-2">
                    <FaSignOutAlt color="black" />
                    <span className="m-1 text-black">Logout</span>
                  </div>
                </li>
                {/* Add more options as needed */}
              </ul>
            </div>}
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
