import React, { useEffect, useState } from 'react';
import { useBlogsContext } from '../../contexts/BlogsContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Link,useNavigate } from 'react-router-dom';
import { baseUrl } from '../../apis/base_url';
import ConfirmationDeletePopUp from '../../components/confirmation_delete_popup';

function AllBlogs() {
   // Define state variables and functions to handle dropdown toggles, search, etc.
   const [showActionsDropdown, setShowActionsDropdown] = useState(false);
   const [showFilterDropdown, setShowFilterDropdown] = useState(false);
   const [searchInput, setSearchInput] = useState('');

   const { getBlogs, allBlogs } = useBlogsContext();
   const { token } = useAuthContext();

   const getAllBlogs = async () => {
    await getBlogs(token);
  }


  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div className='px-5 py-10'>
      {allBlogs ? (
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">Blogs</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form>
            </div>

            {/* Actions and Filter Buttons */}
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            <Link to="/blogs/add" >
              <button
              
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >
                <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add Blogs
              </button>
            </Link>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Introduction
                  </th>
                  
                  {/* <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {
                  allBlogs && allBlogs.map((data, index) => {
                    return <TableRow id={index} data={data} />
                  })
                }

              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
            <span className="text-sm font-normal text-gray-500">
              Showing
              <span className="mx-1 font-semibold text-gray-900">1-10</span>
              of
              <span className="mx-1 font-semibold text-gray-900">1000</span>
            </span>
            {/* Pagination links */}
          </nav>
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}

      {/* add data */}
      {/* AddLocation component */}
      {/* Replace this comment with your AddLocation component */}
      {/* <AddLocation showModel={showAddIconPopup} setShowModel={setShowAddIconPopup} /> */}
    </div>
  );
}

export default AllBlogs;



function TableRow({ id, data }) {
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const navigate = useNavigate()




  return <>  
   <tr onClick={()=>navigate("/blogs/view/"+data.id)} key={id} className="border-b hover:bg-gray-50 cursor-pointer transition-all duration-150 ease-in-out transform-gpu">
  <td className="px-4 py-3 font-medium text-gray-900 ">
   {data.id}
  
  </td>
  <td className="px-4 py-3 font-medium text-gray-900 ">
   {data.title ? data.title : ""}
  
  </td>
  <td className="px-4 py-3 font-medium text-gray-900">
    {data.introduction ? data.introduction : ""}
  </td>

    {/* <td className="px-2 py-3">
      <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700">
        <Link to={"/blogs/view/"+data.id} >
          <li>    
            <img src={'images/sidebar/Show.svg'} alt="Show" />  
          </li>
        </Link>
        <li className="cursor-pointer" onClick={() => setShowUpdatePopup(true)}>
          <img src={'images/sidebar/Edit.svg'} alt="Edit" />
        </li>
        <li className="cursor-pointer" onClick={() => setShowDeletePopup(true)}>
          <img src={'images/sidebar/Delete.svg'} alt="Delete" />
        </li>

      
        <ConfirmationDeletePopUp handleCancel={() => setShowDeletePopup(false)} handleDelete={() => handleDeleteButtonClick(data.id)} title={"Are you sure you want to delete this data."} showModel={showDeletePopup} setShowModel={setShowDeletePopup} />
      </ul>
    </td>  */}

  </tr>
  </>
}
















































































// <div className='px-5 py-10'>
//      <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
//      <h1 className="font-semibold text-3xl px-4 pt-4">BLOGS</h1>
       
//        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
//        <div className="w-full md:w-1/2">
//          {/* search */}
//          <form className="flex items-center">
//            <label htmlFor="simple-search" className="sr-only">
//              Search
//            </label>
//            <div className="relative w-full">
//              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
//                <svg
//                  aria-hidden="true"
//                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
//                  fill="currentColor"
//                  viewBox="0 0 20 20"
//                  xmlns="http://www.w3.org/2000/svg"
//                >
//                  <path
//                    fillRule="evenodd"
//                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
//                    clipRule="evenodd"
//                  />
//                </svg>
//              </div>
//              <input
//                type="text"
//                id="simple-search"
//                className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
//                placeholder="Search"
//                required
//                value={searchInput}
//                onChange={(e) => setSearchInput(e.target.value)}
//              />
//            </div>
//          </form>
//        </div>

//           {/* Actions and Filter Buttons */}
//        <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
//          <Link to="/blogs/add" >
//          <button
           
//            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
//          >
//            <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
//              <path
//                clipRule="evenodd"
//                fillRule="evenodd"
//                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
//              />
//            </svg>
//            Add Blogs
//          </button>

//          <button
//            onClick={toggleActionsDropdown}
//            data-dropdown-toggle="actionsDropdown"
//            className={`relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover-bg-gray-700 ${showActionsDropdown ? 'bg-primary-100 text-primary-700' : ''}`}
//            type="button"
//          >
           
//            Actions
//            <svg className="w-5 h-5 -rotate-90 ms-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
//                </svg>
//            <div
//              id="actionsDropdown"
//              className={`absolute right-0 z-10 ${showActionsDropdown ? 'block' : 'hidden'} bg-white divide-y divide-gray-100 rounded shadow top-10 w-44 dark:bg-gray-700 dark:divide-gray-600`}
//            >
//              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
//                <li>
//                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
//                    Mass Edit
//                  </a>
//                </li>
//              </ul>
//              <div className="py-1">
//                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover-bg-gray-100 dark-hover-bg-gray-600 dark-text-gray-200 dark-hover-text-white">
//                  Delete all
//                </a>
//              </div>
//            </div>
//          </button>

//          <button
//            onClick={toggleFilterDropdown}
//            data-dropdown-toggle="filterDropdown"
//            className={`relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover-bg-gray-100 hover-text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark-bg-gray-800 dark-text-gray-400 dark-border-gray-600 dark-hover-text-white dark-hover-bg-gray-700 ${showFilterDropdown ? 'bg-primary-100 text-primary-700' : ''}`}
//            type="button"
//          >
          
//            Filter
//            <svg className="w-5 h-5 -rotate-90 ms-3" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
//                </svg>
//            <div
//              id="filterDropdown"
//              className={`absolute right-0 z-10 ${showFilterDropdown ? 'block' : 'hidden'} bg-white divide-y divide-gray-100 rounded shadow top-10 w-44 dark:bg-gray-700 dark:divide-gray-600`}
//            >
//              <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="filterDropdownButton">
//                <li>
//                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
//                    Filter 1
//                  </a>
//                </li>
//                <li>
//                  <a href="#" className="block px-4 py-2 hover-bg-gray-100 dark-hover-bg-gray-600 dark-hover-text-white">
//                    Filter 2
//                  </a>
//                </li>
//              </ul>
//            </div>
//          </button>
//        </div>
//        </div>

//        <div className="overflow-x-auto ">
//          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
//            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//              <tr>
//                <th scope="col" className="px-4 py-3">
//                  Title
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  Category
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  No of Persons
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  Price
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  Start Time
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  End Time
//                </th>
//                <th scope="col" className="px-4 py-3">
//                  Actions
//                </th>
//              </tr>
//            </thead>
//            <tbody>
//              {/* Table rows */}
//              {/* Map your data here and create rows as needed */}
//              <tr className="border-b dark:border-gray-700">
//                <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
//                  Hasnat
//                </td>
//                <td className="px-4 py-3">abc</td>
//                <td className="px-4 py-3">123</td>
//                <td className="px-4 py-3">300</td>
//                <td className="px-4 py-3">13-03-2002</td>
//                <td className="px-4 py-3">14-03-2023</td>
//                <td className="px-2 py-3">
//                  <ul className="flex items-center justify-center gap-3 py-1 text-sm text-gray-700 dark:text-gray-200">
//                    <li>
//                      <a href="#">
//                        <img src={'images/sidebar/Show.svg'} alt="Show" />
//                      </a>
//                    </li>
//                    <li>
//                      <a href="#">
//                        <img src={'images/sidebar/Edit.svg'} alt="Edit" />
//                      </a>
//                    </li>
//                    <li>
//                      <a href="#">
//                        <img src={'images/sidebar/Delete.svg'} alt="Delete" />
//                      </a>
//                    </li>
//                  </ul>
//                </td>
//              </tr>
//              {/* Map more rows here */}
//            </tbody>
//          </table>
//        </div>
//        <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
//          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
//            Showing
//            <span className="mx-1 font-semibold text-gray-900 dark:text-white">1-10</span>
//            of
//            <span className="mx-1 font-semibold text-gray-900 dark:text-white">1000</span>
//          </span>
//          <ul className="inline-flex items-stretch -space-x-px">
//            <li>
//              <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-bg-gray-800 dark-border-gray-700 dark-text-gray-400 dark-hover-text-white dark-hover-bg-gray-700">
//                <span className="sr-only">Previous</span>
//                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
//                </svg>
//              </a>
//            </li>
//            <li>
//              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">1</a>
//            </li>
//            <li>
//              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">2</a>
//            </li>
//            <li>
//              <a href="#" aria-current="page" className="z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover-bg-primary-100 hover-text-primary-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">3</a>
//            </li>
//            <li>
//              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">...</a>
//            </li>
//            <li>
//              <a href="#" className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-border-gray-700 dark-bg-gray-700 dark-text-white">100</a>
//            </li>
//            <li>
//              <a href="#" className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover-bg-gray-100 hover-text-gray-700 dark-bg-gray-800 dark-border-gray-700 dark-text-gray-400 dark-hover-text-white dark-hover-bg-gray-700">
//                <span className="sr-only">Next</span>
//                <svg className="w-5 h-5 rotate-180" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
//                </svg>
//              </a>
//            </li>
//          </ul>
//        </nav>
//      </div>
//    </div>