

import { useParams } from "react-router-dom";


import { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useContactSupportContext } from "../../contexts/ContactSupportContextProvider";


const ViewQuery = () => {

    const { id } = useParams();
    const [currentQuery, setCurrentQuery] = useState(null)
    const { token } = useAuthContext();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { allQueries,
        getAllQueries,
    } = useContactSupportContext();


    const fetchCurrentQuery = async () => {
        setLoading(true);
        allQueries && setCurrentQuery(allQueries.find(item => item.id == id))
        setLoading(false);
    }



    useEffect(() => {
        fetchCurrentQuery();
    }, [allQueries]);




    const fetchAllQueries = async () => {
        await getAllQueries(token);
    }


    useEffect(() => {
        fetchAllQueries();
    }, []);

    return (
        <section>
            {currentQuery ? <div>
                <div className="md:w-[90%] relative w-full md:p-0 px-4 my-8 mx-auto flex flex-col gap-4">

                    <div className="relative">
                        <button
                            onClick={() => navigate("/queries")}
                            className=" left-0 z-50 flex mb-4 items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
                        >
                            <IoIosArrowBack
                                size={28}
                                className="text-black cursor-pointer"
                            />
                        </button>

                    </div>
                    <h1
                        className="rounded-full  font-semibold text-center text-3xl pt-1"

                    > {currentQuery?.name} </h1>

                    <h1
                        className="rounded-full  font-semibold  text-2xl pt-1"

                    > Email: </h1>

                    <p
                        className="rounded-full  font-normal  text-base pt-1"

                    > {currentQuery?.email} </p>



                    <h1
                        className="rounded-full  font-semibold  text-2xl pt-1"

                    >  Context:</h1>
                    <p
                        className="rounded-full  font-normal  text-base pt-1"

                    > {currentQuery?.context} </p>

                    <h1
                        className="rounded-full  font-semibold  text-2xl pt-1"

                    >  Message:</h1>
                    <p
                        className="rounded-full  font-normal  text-base pt-1"

                    > {currentQuery?.message} </p>


                </div>
            </div> :
                loading ? <h1 className="my-10 text-base text-center w-full font-bold">Loading ...</h1> : <h1 className="my-10 text-base text-center w-full font-bold">No blog found.</h1>

            }
        </section>
    );
};

export default ViewQuery;
