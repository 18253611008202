import logo from "./logo.svg";
import "./App.css";
import Layout from "./layout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./pages/auth/login";
import Dashboard from "./pages/dashboard";
import AllEvents from "./pages/events/all_events";
import AllLocations from "./pages/locations/all_locations";
import AllCategories from "./pages/categories/all_categories";
import AllCoordinators from "./pages/coordinators/all_coordinator";
import AllBlogs from "./pages/blogs/all_blogs";
import AllUsers from "./pages/users/all_users";
import Settings from "./pages/settings";
import CreateEventTabs from "./pages/events/add event/create_event_tabs";
import { useAuthContext } from "./contexts/AuthContextProvider";
import AddCategory from "./pages/categories/add_category";
import { ColorRing } from 'react-loader-spinner'
import AllIcons from "./pages/icons/all_icons";
import AddBlog from "./pages/blogs/add_blog";
import ViewBlog from "./pages/blogs/view_blog";
import EditBlog from "./pages/blogs/edit_blog";
import ContactSupport from "./pages/customer-support/all_queries";
import ViewQuery from "./pages/customer-support/view_query";
import AllAppMedia from "./pages/app-media/all_app_media";
import EventById from "./pages/events/event_by_id";
import EditEvent from "./pages/events/edit event/edit_event";
import EditAccomodation from "./pages/events/edit event/edit_accomodation";

function App() {

  const { checkUser, isUserLoggedIn } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const fetchCheckUser = async () => {
    setLoading(true)
    await checkUser();
    setLoading(false)

  }

  useEffect(() => {
    fetchCheckUser();
  }, []);


  return (
    <div>
      {
        !loading ?
          <Routes>
            {/* If the user is logged in, show the Layout and other routes */}

            {!(isUserLoggedIn()) ? (
              <Route path="/" element={<Login />} />
            ) : (
              <>
                <Route path="/" element={<Layout />}>
                  <Route index={true} element={<Dashboard />} />
                  <Route path="/create-event" element={<CreateEventTabs />} />
                  <Route path="/all-events" element={<AllEvents />} />
                  <Route path="/event/view/:id" element={<EventById />} />
                  <Route path="/event/edit/event-details/:id" element={<EditEvent />} />
                  <Route path="/event/edit/event-accomodations/:id" element={<EditAccomodation />} />
                  <Route path="/location" element={<AllLocations />} />
                  <Route path="/categories" element={<AllCategories />} />
                  <Route path="/categories/create" element={<AddCategory />} />
                  <Route path="/coordinators" element={<AllCoordinators />} />
                  <Route path="/blogs" element={<AllBlogs />} />
                  <Route path="/blogs/add" element={<AddBlog />} />
                  <Route path="/blogs/view/:id" element={<ViewBlog />} />
                  <Route path="/blogs/edit/:id" element={<EditBlog />} />
                  <Route path="/users" element={<AllUsers />} />
                  <Route path="/setting" element={<Settings />} />
                  <Route path="/icons" element={<AllIcons />} />
                  <Route path="/queries" element={<ContactSupport />} />
                  <Route path="/app-media" element={<AllAppMedia />} />
                  <Route path="/queries/view/:id" element={<ViewQuery />} />
                </Route>
              </>
            )}

            {/* Redirect to the login page for any other unknown routes */}
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Routes> :
          <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          /></div>
      }

    </div>
  );
}

export default App;
