import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useLocationContext } from '../../contexts/LocationContextProvider';
// import AddLocation from './add_location';
// import EditCategory from './edit_location';
import ConfirmationDeletePopUp from '../../components/confirmation_delete_popup';
// import EditLocation from './edit_location';
import { FaTrash } from 'react-icons/fa'; // Import the delete icon

import { useAppMediaContext } from '../../contexts/AppMediaContextProvider';
import { baseUrl } from '../../apis/base_url';
import AddAppMedia from './add_app_media';

function AllAppMedia() {
  const { token } = useAuthContext();
  const { allAppMedia,
    getAllAppMedia,
    addAllAppMedia,
    deleteAllAppMedia, } = useAppMediaContext();

  const [showAddLocationPopup, setShowAddLocationPopup] = useState(false);

  const [showDeleteVisualPopUp, setShowDeleteVisualPopUp] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showErrorModel, setShowErrorModel] = useState(false)
  const [error, setError] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false)

  const fetchAllAppMedia = async () => {
    await getAllAppMedia(token);
  }

  // Function to handle checkbox selection
  const handleCheckboxChange = (imageId) => {
    if (selectedImages.includes(imageId)) {
      // If already selected, remove it from the list
      setSelectedImages(selectedImages.filter(id => id !== imageId));
    } else {
      // If not selected, add it to the list

      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const handleVisualsDelete = () => {
    if (selectedImages.length === 0) {
      // Show error pop-up when no images are selected
      // Implement your logic to display an error message or a pop-up here
      setError("Select images first!"); // For demonstration
      setShowErrorModel(true);
      return;
    }
    else {

      setShowDeleteVisualPopUp(true);
    }
    // Continue with delete operation or perform other actions...
  };

  const handleVisualsDeleteConfirm = async () => {
    setError('');

    const response = await deleteAllAppMedia(token, selectedImages);
    if (!response) {
      setError("Please check your credentials again.");

      setShowErrorModel(true);
    }
    else {

      //   setLoading(false);
      setShowSuccessPopUp(true)

    }

    setShowDeleteVisualPopUp(false);


  }

  const hideError = () => {
    setTimeout(() => {
      setError('');
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  // Effect to trigger hiding the error message whenever 'error' state changes
  useEffect(() => {
    if (error) {
      hideError();
    }
  }, [error]);


  useEffect(() => {
    fetchAllAppMedia();
  }, []);

  return (
    <div className='px-5 py-10'>
      {allAppMedia ? (
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">App Media</h1>

          <div className="flex flex-col items-center justify-end p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">


            {/* Actions and Filter Buttons */}
            <div className="flex flex-col items-center w-[100%] justify-end flex-shrink-0  gap-4 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
              {error && <p className='text-red-500 '>{error}</p>}
              {
                allAppMedia && allAppMedia.length > 0 &&
                <div className="flex items-center justify-center ">
                  {/* edit image */}
                  <FaTrash
                    onClick={handleVisualsDelete}
                    className=" cursor-pointer"
                    alt="delete"
                    color='red'
                    size={23}
                  />
                </div>
              }

              <button
                type="button"
                onClick={() => setShowAddLocationPopup(true)}
                className="flex items-center justify-center px-5  py-3 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300"
              >
                <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add Images
              </button>

            </div>
          </div>



          <ConfirmationDeletePopUp
            showModel={showDeleteVisualPopUp}
            setShowModel={setShowDeleteVisualPopUp}
            title={"Are you sure you want to delete these visuals?"}
            handleCancel={() => { setShowDeleteVisualPopUp(false); setSelectedImages(null); }}
            handleDelete={() => handleVisualsDeleteConfirm()}
          />

          {/* images list */}
          <div className="flex flex-wrap items-center w-full gap-2 my-4 md:ml-2">
            {
              allAppMedia && allAppMedia.map(item => {
                return <div key={item.id} className="relative md:w-[12.3rem] w-full h-[12.3rem] ">
                  <img
                    width={300}
                    height={300}
                    src={baseUrl + "/" + item.path + "/" + item.name}
                    alt="Profile"
                    className="object-cover object-center md:w-[12.3rem] w-full h-[12.3rem] rounded-md shadow-md  "
                  />
                  <div className="top-0 rounded-t-md opacity-[50%] absolute h-[5rem] w-full bg-gradient-to-b from-black to-[#FFFFFF00] " />
                  <div className="absolute top-3 right-3 ">
                    <label htmlFor={`checkbox-${item.id}`}>
                      <input
                        id={`checkbox-${item.id}`}
                        checked={selectedImages.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                        type="checkbox"
                        className="w-4 h-4 border-2 rounded-sm  cursor-pointer"
                      />
                    </label>
                  </div>
                </div>
              })
            }

          </div>

        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}

      {/* add location */}
      {/* AddLocation component */}
      {/* Replace this comment with your AddLocation component */}
      <AddAppMedia showModel={showAddLocationPopup} setShowModel={setShowAddLocationPopup} />
    </div>
  );
}

export default AllAppMedia;


