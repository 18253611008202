// AuthContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [token , setToken] = useState(null)
  const [error, setError] = useState(null);

  const login = async (credentials) => {
    try {
      
        const headers = {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content"),
          
        };
  
        const response = await ApiTemplate(
          "post",
          "/api/v1/admin/login",
          JSON.stringify( {
            email: credentials.email,
            password: credentials.password,
          } ),
          headers,

        );
        if (response && response["success"] === true) {
          console.log(response["data"]);
         
          await localStorage.setItem("token", JSON.stringify(response["data"]["token"]));
          await localStorage.setItem("user", JSON.stringify(response["data"]["user"]));
        
          setIsLoggedIn(true);
          setUserProfile(response["data"]["user"]);
          setToken(response["data"]["token"]);
          // window.location.href="/" ;
        } else {
          // console.log("error: ", response)
          setError("Please check your credentials again.");
        }
      
    } catch (error) {
      console.log("error.message message: ", error.message)

      setError(error.message)
    }
  };

  const logout = async () => {
    // Implement the logic to log out the user, e.g., clearing tokens or session data
    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    setIsLoggedIn(false);
    setUserProfile(null);
    setToken(null);
  };

  const isUserLoggedIn = () => {
    return isLoggedIn;
  };

  const checkUser = async () => {
    try {
      console.log(" checking user");
      const token = JSON.parse(await localStorage.getItem("token"));
      if (token !== null) {
        const headers = {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content"),
          "Authorization": "Bearer " + token,
        };

        const response = await ApiTemplate(
          "get",
          "/api/v1/verify/token",
          {},
          headers,
        );

        if (response && response["success"] === true) {
          console.log(response["data"]);
          // await localStorage.setItem("token", JSON.stringify(response["data"]["token"]));
          await localStorage.setItem("user", JSON.stringify(response["data"]));
          setIsLoggedIn(true);
          setUserProfile(response["data"]);
          setToken(token);

        } else {
          setIsLoggedIn(false);
          setUserProfile(null);
        }


      } else {
        setIsLoggedIn(false);
        setUserProfile(null);
      }
    } catch (error) {
      console.error("Error while checking user status:", error);
    }
  };

  const getUserProfile = () => {
    return userProfile;
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isUserLoggedIn,
        checkUser,
        getUserProfile,
        userProfile,
        error,
        setError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const authContextValue = useContext(AuthContext);
  return authContextValue;
};
