import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useEventContext } from '../../contexts/EventContextProvider';
import { Link, useParams } from 'react-router-dom';
import { baseUrl } from '../../apis/base_url';
import { MdModeEdit } from "react-icons/md";
import ConfirmationDeletePopUp from '../../components/confirmation_delete_popup';


function EventById() {
    const { id } = useParams();
    const [showActionsDropdown, setShowActionsDropdown] = useState(false);
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [searchInput, setSearchInput] = useState('');
    const { token } = useAuthContext();
    const { getEventbyId,
        singleEventId, } = useEventContext();

    const getAllEvents = async () => {
        await getEventbyId(token, id);
    }

    useEffect(() => {
        getAllEvents();
    }, [id]);

    const renderSection = (title, data) => {
        if (!data || data.trim() === '') {
            return null;
        }
        return (
            <div>
                <h4 className="font-semibold mb-2">{title}</h4>
                <p className="text-gray-700 mb-2">{data}</p>
            </div>
        );
    };

    const handleDeleteButtonClick = async (blogId) => {
        // Additional actions or state changes can be performed here
        // const response = await deleteBlog(token, blogId);
        // if (response) {
        //     setShowDeletePopup(false);
        // }

    };



    return (
        <div className='px-5 py-10'>
            {singleEventId ? (
                <div className="relative flex flex-col gap-4">


                    {/* event details */}
                    <div className="p-4 relative bg-white shadow-md rounded-md">
                        <Link to={"/event/edit/event-details/" + singleEventId.event.id}>
                            <MdModeEdit size={40} className="absolute top-4 right-4 p-2 rounded-full bg-white shadow-xl  text-gray-500 cursor-pointer" />
                        </Link>
                        <h1 className="font-semibold text-3xl px-4 pt-4">Event Details</h1>
                        <div className="">
                            <p className="text-gray-700 mb-2"><strong>Card Image:</strong></p>
                            <div className="bg-cover bg-center h-64 rounded-md shadow-md" style={{ backgroundImage: `url(${baseUrl}/${singleEventId?.event?.card_image.path}/${singleEventId?.event?.card_image.name})` }}></div>
                            <p className="text-gray-700 mb-2"><strong>Banner Image:</strong></p>
                            <div className="bg-cover bg-center h-64 rounded-md shadow-md" style={{ backgroundImage: `url(${baseUrl}/${singleEventId?.event?.banner_image.path}/${singleEventId?.event?.banner_image.name})` }}></div>

                            <h2 className="text-xl font-semibold mb-2 pt-4"><strong className=' text-gray-600 font-bold text-[15px]'>Title:</strong> {singleEventId?.event?.title}</h2>
                            <p className="text-gray-600 mb-4"><strong>Description:</strong> {singleEventId?.event?.description}</p>
                            <p className="text-gray-700 mb-2"><strong>Date:</strong> {singleEventId?.event?.date_at}</p>
                            <p className="text-gray-700 mb-2"><strong>Duration:</strong> {singleEventId?.event?.duration} days</p>
                            <p className="text-gray-700 mb-2"><strong>Location:</strong> {singleEventId?.event?.location}</p>
                            <p className="text-gray-700 mb-2"><strong>Price:</strong> ${singleEventId?.event?.price}</p>
                            <p className="text-gray-700 mb-2"><strong>Total Group Members:</strong> {singleEventId?.event?.total_group_members}</p>
                            <p className="text-gray-700 mb-2"><strong>Category:</strong> {singleEventId?.event?.category.name}</p>
                            {/* Add other event details as needed */}
                        </div>
                    </div>

                    {/* event accomodation */}
                    <div className="p-4 relative bg-white shadow-md rounded-md">
                        <Link to={"/event/edit/event-accomodations/" + singleEventId.event.id}>
                            <MdModeEdit size={40} className="absolute top-4 right-4 p-2 rounded-full bg-white shadow-xl  text-gray-500 cursor-pointer" />
                        </Link>
                        <h2 className="font-semibold text-2xl mb-4">Accommodation Details</h2>
                        {singleEventId?.accomodation.map((accommodation) => (
                            <div key={accommodation.id} className="mb-4 bg-white rounded-md shadow-md p-4">
                                <p className="text-gray-700 mb-2"><strong>Type:</strong> {accommodation.type}</p>
                                <p className="text-gray-700 mb-2"><strong>No. of Persons:</strong> {accommodation.no_of_persons}</p>
                                <p className="text-gray-700 mb-2"><strong>Price:</strong> ${accommodation.price}</p>
                            </div>
                        ))}
                    </div>

                    {/* event features */}
                    <div className="p-4 relative bg-white shadow-md rounded-md">
                        <MdModeEdit size={40} className="absolute top-4 right-4 p-2 rounded-full bg-white shadow-xl  text-gray-500 cursor-pointer" />

                        <h2 className="font-semibold text-2xl mb-4">Event Features</h2>
                        {singleEventId?.features.map((feature) => (
                            <div key={feature.id} className="mb-4 bg-white rounded-md shadow-md p-4">
                                <div className="flex items-center mb-3">
                                    <img src={`${baseUrl}/${feature.icon.path}`} alt={feature.title} className="w-8 h-8 mr-3" />
                                    <h3 className="text-xl font-semibold">{feature.title}</h3>
                                </div>
                                <p className="text-gray-700 mb-2">{feature.description}</p>
                            </div>
                        ))}
                    </div>

                    {/* event plans */}
                    <div className="p-4 relative bg-white shadow-md rounded-md">
                        <MdModeEdit size={40} className="absolute top-4 right-4 p-2 rounded-full bg-white shadow-xl  text-gray-500 cursor-pointer" />

                        <h2 className="font-semibold text-2xl mb-4">Event Plans</h2>
                        {singleEventId?.plan.map((plan) => (
                            <div key={plan.id} className="mb-4 bg-white rounded-md shadow-md p-4">
                                <h3 className="text-xl font-semibold mb-2">{plan?.day_date}</h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {renderSection('Morning', plan.morning)}
                                    {renderSection('Lunch Break', plan.lunch_break)}
                                    {renderSection('Afternoon', plan.afternoon)}
                                    {renderSection('Evening', plan.evening)}
                                    {renderSection('Dinner', plan.dinner)}
                                    {renderSection('Night Time', plan.night_time)}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* events extra images */}
                    <div className="p-4 relative bg-white shadow-md rounded-md">
                        <MdModeEdit size={40} className="absolute top-4 right-4 p-2 rounded-full bg-white shadow-xl  text-gray-500 cursor-pointer" />

                        <h2 className="font-semibold text-2xl mb-4">Extra Images</h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {singleEventId?.extra_images.map((image) => (
                                <div key={image.id} className="relative overflow-hidden rounded-md shadow-md">
                                    <img
                                        src={`${baseUrl}/${image.path}/${image.name}`}
                                        alt={image.file_name}
                                        className="object-cover w-full h-40 md:h-48 lg:h-56"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* edit and delete button */}
                    <div className="w-full flex gap-4 items-center justify-center mt-5">
                        <button onClick={() => setShowDeletePopup(true)} className="px-12 py-2 bg-black border-black border-2 text-white rounded-md">Delete Event</button>
                    </div>
                    <ConfirmationDeletePopUp handleCancel={() => setShowDeletePopup(false)} handleDelete={() => handleDeleteButtonClick(id)} title={"Are you sure you want to delete this event."} showModel={showDeletePopup} setShowModel={setShowDeletePopup} />
                </div>
            ) : (
                <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
                    <h5 className="font-bold text-center text-md">Loading ....</h5>
                </div>
            )}
        </div>
    );
}

export default EventById;
