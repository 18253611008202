import React from 'react';
import Sidebar from './sidebar';
import Header from './navbar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className="grid bg-white grid-cols-5 mx-auto border-gray-100 font-inter max-w-screen-2xl border-x-2">
            <Sidebar />
            <div className="col-span-4 bg-gray-100">
                <Header />
                <main className=""><Outlet /></main>
            </div>
        </div>
    );
};

export default Layout;
