import React, { useRef, useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useEffect } from 'react';
import { BsCamera } from "react-icons/bs";

import { useLocationContext } from '../../contexts/LocationContextProvider';
import { baseUrl } from '../../apis/base_url';
import { useIconsContext } from '../../contexts/IconsContextProvider';

function EditIcon({
    showModel,
    setShowModel,
    id,
    path,
}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const [iconName, setIconName] = useState('');
    const [imagePath, setImagePath] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { updateIcon } = useIconsContext();
    const { token } = useAuthContext();

    const handleImageUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = e => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setSelectedImage({ file: file, base64: e.target.result });
            };
            reader.readAsDataURL(file);
        }
    };
    const handleInputChange = (e) => {
        setIconName(e.target.value);
        setError(''); // Clearing any previous error when user starts typing
    };

    useEffect(() => {
        setImagePath(path);
    }, [path])

    const handleNextButtonClick = async () => {
        setLoading(true);
        if (selectedImage && !iconName) {
            setError('Please enter a Icon name');
            setLoading(false);
            return;
        }
        if (!selectedImage) {
            setShowModel(false);
            setLoading(false);
            return;
        }


        const data = new FormData();
        data.append("name", iconName);
        data.append("icon",selectedImage.file)

        // Additional actions or state changes can be performed here
        const response = await updateIcon(token,id, data);
        // Reset input field after processing
        if (!response) {
            setError("Oops! Icon not updated.please try again.")
        }
        else {
            setShowModel(false);
        }

        setLoading(false);

    };

    return (
        <div>{showModel &&
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                    onClick={() => setShowModel(false)}
                    className="fixed inset-0 bg-black opacity-[66%]"
                />
                <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
                    <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                        <div className='flex gap-3 items-center justify-center w-full mb-14'>
                            <h1 className='font-semibold text-2xl text-center'>Update Icon</h1>
                        </div>

                        <div className='flex flex-col items-center gap-10 mt-5'>
                            {/* image */}
                            <div>
                                <div
                                    className={
                                        selectedImage
                                            ? "relative w-max mx-auto "
                                            : "relative w-max mx-auto text-gray-500  rounded-full"
                                    }
                                >
                                    {selectedImage
                                        ? <div>
                                            <img
                                                src={selectedImage.base64}
                                                alt="Selected"
                                                width={100}
                                                height={100}
                                                className="object-cover rounded-full shadow-md w-28 h-28"
                                            />
                                            <div className="absolute bottom-0 right-0 z-50 flex items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min">
                                                <BsCamera
                                                    size={28}
                                                    className="text-gray-500 cursor-pointer"
                                                    onClick={handleImageUploadClick}
                                                />
                                            </div>
                                        </div>
                                        : <>
                                            {
                                                imagePath && imagePath ?
                                                    <>
                                                        {" "}
                                                        <img
                                                            src={baseUrl +"/"+ imagePath}
                                                            alt="Selected"
                                                            width={100}
                                                            height={100}
                                                            className="object-cover w-40 h-40 rounded-full shadow-md"
                                                        />
                                                        <div className="absolute right-0 z-50 flex items-center justify-center p-2 bg-white rounded-full shadow-md bottom-3 w-min h-min">
                                                            <BsCamera
                                                                size={28}
                                                                className="text-gray-500 cursor-pointer"
                                                                onClick={handleImageUploadClick}
                                                            />
                                                        </div>
                                                    </> : <div className="flex items-center justify-center py-8 bg-gray-300 rounded-full shadow-md px-7">
                                                        <img
                                                            src={"/images/upload_icon.svg"}

                                                            className="text-gray-500 cursor-pointer"
                                                            onClick={handleImageUploadClick}
                                                        />
                                                    </div>
                                            }
                                        </>}
                                </div>
                                <input
                                    type="file"
                                    id="image"
                                    ref={fileInputRef}
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                            </div>

                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    value={iconName}
                                    onChange={handleInputChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Name</p>
                            </div>



                            <div className='flex flex-col items-center justify-center w-full gap-2'>
                                {error && <p className='text-red-500'>{error}</p>}
                                <button
                                    onClick={handleNextButtonClick}
                                    className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                                >
                                    {loading
                                        ? <div className="flex items-center justify-center w-full">
                                            <ThreeDots
                                                color="#ffffff"
                                                height={20}
                                                width={40}
                                                className="py-2"
                                            />
                                        </div>
                                        : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default EditIcon;
