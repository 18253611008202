import {useState} from 'react';
import {useAuthContext} from '../../../contexts/AuthContextProvider';
import {ThreeDots} from 'react-loader-spinner';

export default function Login () {
  const [loading, setLoading] = useState (false);
  const {login, error, setError} = useAuthContext ();
  const [showPassword, setShowPassword] = useState (false);
  const [formData, setFormData] = useState ({
    email: '',
    password: '',
    rememberMe: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword (!showPassword);
  };

  const handleInputChange = e => {
    const {name, value, type, checked} = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData ({
      ...formData,
      [name]: newValue,
    });
  };

  const handleLogin = async () => {
    setError ('');
    setLoading (true);
    if (!formData.email || !formData.password) {
      setError ('Email and password are required');
      setLoading (false);
      return;
    }

    // Log the form data to the console
    console.log ('Form Data:', formData);

    await login (formData);
    setLoading (false);

    // Set user as logged in (you can replace this with actual login logic)
    // setIsUserLoggedIn(true);
  };

  return (
    <section className="relative min-h-screen">
      <div className="flex items-center w-full justify-center py-10">
        <img src={'/images/sidebar/logo.svg'} alt="Logo" />
      </div>
      <div className="flex items-center pt-16 justify-center h-full w-full">
        <div className="w-full md:w-[30rem] border rounded-3xl shadow-md md:px-2 py-2 bg-white z-30">
          <div className="flex items-start justify-start w-full">
            <div className="w-full">
              <div className="w-full p-3 space-y-5">
                <div>
                  <h2 className="text-[28px] mb-2 font-semibold">Login</h2>
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-normal text-gray-500"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      required
                      className="mt-1 px-3 py-3 w-full rounded-md text-base font-normal border border-gray-300 focus:outline-none focus:ring focus:border-green-400 focus:bg-white"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-normal text-gray-500"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        required
                        placeholder="Enter Password"
                        className="mt-1 px-3 py-3 w-full border border-gray-300 focus:outline-none focus:ring focus:border-green-400 rounded-md text-base font-normal"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                      <button
                        onClick={togglePasswordVisibility}
                        className="absolute inset-y-0 right-0 px-3 py-2 mt-1 text-gray-400 hover:text-gray-600"
                      >
                        {showPassword
                          ? <img src={'/images/auth/eye.svg'} alt="Eye" />
                          : <img src={'/images/auth/hide_eye.svg'} alt="Eye" />}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between text-sm font-normal">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      className="rounded-sm border-2 w-4 h-4 cursor-pointer"
                      checked={formData.rememberMe}
                      onChange={handleInputChange}
                    />
                    <label
                      htmlFor="rememberMe"
                      className="text-gray-500 cursor-pointer ms-2"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>

                {error &&
                  <p className="text-sm font-normal text-red-500 text-center">
                    {error}
                  </p>}

                <div className="py-5">
                  <button
                    onClick={handleLogin}
                    className="bg-black text-white w-full py-3 mb-4 rounded-md text-base font-medium"
                  >
                    {loading
                      ? <div className="flex items-center justify-center w-full">
                          <ThreeDots
                            color="#ffffff"
                            height={20}
                            width={40}
                            className="py-2"
                          />
                        </div>
                      : 'Login'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
