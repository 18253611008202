import React, { useState } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContextProvider';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { ThreeDots } from 'react-loader-spinner';
import { useLocationContext } from '../../contexts/LocationContextProvider';

function AddLocation({
    showModel,
    setShowModel
}) {
    const [locationName, setLocationName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { addLocation } = useLocationContext();
    const { token } = useAuthContext();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'locationName') {
            setLocationName(value);
        } else if (name === 'latitude') {
            setLatitude(value);
        } else if (name === 'longitude') {
            setLongitude(value);
        }
        setError(''); // Clearing any previous error when user starts typing
    };


    const handleNextButtonClick = async () => {
        setLoading(true);
        if (!locationName) {
            setError('Please enter a Location name');
            setLoading(false);
            return;
        }
        if (!latitude) {
            setError('Please enter a latitude.');
            setLoading(false);
            return;
        }
        if (!longitude) {
            setError('Please enter a longitude.');
            setLoading(false);
            return;
        }

       
        // Additional actions or state changes can be performed here
        const response = await addLocation(token, JSON.stringify({
            name: locationName, 
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
        }));
        // Reset input field after processing
        if (!response) {
            setError("Oops! Location not added.please try again.")

        }
        else {
            setShowModel(false);

        }
        setLocationName('');
        setLoading(false);

    };

    return (
        <div>{showModel &&
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                    onClick={() => setShowModel(false)}
                    className="fixed inset-0 bg-black opacity-[66%]"
                />
                <div className="relative z-50 md:w-auto w-[95%] md:min-w-[28rem] mx-auto my-6">
                    <div className="relative px-6 py-6 bg-white rounded-lg shadow-lg">
                        <div className='flex gap-3 items-center justify-center w-full mb-14'>
                            <h1 className='font-semibold text-2xl text-center'>Add Loction</h1>
                        </div>

                        <div className='flex flex-col items-center gap-10 mt-5'>
                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    name='locationName'
                                    value={locationName}
                                    onChange={handleInputChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Name</p>
                            </div>
                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    name='latitude'
                                    value={latitude}
                                    onChange={handleInputChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                    placeholder='Latitude'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Latitude</p>
                            </div>

                            <div className='relative border rounded-md border-black px-2 py-4 w-full'>
                                <input
                                    type='text'
                                    name='longitude'
                                    value={longitude}
                                    onChange={handleInputChange}
                                    className='w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                                    placeholder='Longitude'
                                />
                                <p className='absolute -top-3 left-5 bg-white px-2'>Longitude</p>
                            </div>


                            <div className='flex flex-col items-center justify-center w-full gap-2'>
                                {error && <p className='text-red-500'>{error}</p>}
                                <button
                                    onClick={handleNextButtonClick}
                                    className='w-full py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold'
                                >
                                    {loading
                                        ? <div className="flex items-center justify-center w-full">
                                            <ThreeDots
                                                color="#ffffff"
                                                height={20}
                                                width={40}
                                                className="py-2"
                                            />
                                        </div>
                                        : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AddLocation;
