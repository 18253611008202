import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useContactSupportContext } from '../../contexts/ContactSupportContextProvider';

function ContactSupport() {
  const [searchInput, setSearchInput] = useState('');

  const { allQueries,
    getAllQueries,
  } = useContactSupportContext();
  const { token } = useAuthContext();

  const fetchAllQueries = async () => {
    await getAllQueries(token);
  }


  useEffect(() => {
    fetchAllQueries();
  }, []);

  return (
    <div className='px-5 py-10'>
      {allQueries ? (
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <h1 className="font-semibold text-3xl px-4 pt-4">Contact Support</h1>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              {/* search */}
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="block w-full py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 ps-10 pe-3"
                    placeholder="Search"
                    required
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </form>
            </div>


          </div>

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Id
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Context
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table rows */}
                {
                  allQueries && allQueries.map((data, index) => {
                    return <TableRow id={index} data={data} />
                  })
                }

              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
            <span className="text-sm font-normal text-gray-500">
              Showing
              <span className="mx-1 font-semibold text-gray-900">1-10</span>
              of
              <span className="mx-1 font-semibold text-gray-900">1000</span>
            </span>
            {/* Pagination links */}
          </nav>
        </div>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}

    </div>
  );
}

export default ContactSupport;



function TableRow({ id, data }) {
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const navigate = useNavigate()
  return (
    <tr onClick={() => navigate("/queries/view/" + data.id)} key={id} className="border-b hover:bg-gray-50 cursor-pointer transition-all duration-150 ease-in-out transform-gpu">
      <td className="px-4 py-3 font-medium text-gray-900 ">
        {data.id}

      </td>
      <td className="px-4 py-3 font-medium text-gray-900 ">
        {data.name ? data.name : ""}

      </td>
      <td className="px-4 py-3 font-medium text-gray-900 ">
        {data.email ? data.email : ""}

      </td>
      <td className="px-4 py-3 font-medium text-gray-900">
        {data.context ? data.context : ""}
      </td>
    </tr>);
}






