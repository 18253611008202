// EventContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader, multiFormHeader } from "../apis/header";

const AppMediaContext = createContext();

export const AppMediaContextProvider = ({ children }) => {
    const [allAppMedia, setAllAppMedia] = useState(null);

    const getAllAppMedia = async (token) => {
        try {
            const headers = jsonHeader(token)

            const response = await ApiTemplate("get", "/api/v1/get/app/media", {}, headers);
            if (response && response["success"] === true) {
                console.log(response["data"]);
                setAllAppMedia(response["data"]);
            } else {
                console.log(response["message"], response.message);
            }
        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    const addAllAppMedia = async (token, eventData) => {
        try {
            const headers = multiFormHeader(token);

            const response = await ApiTemplate("post", "/api/v1/store/app/media", eventData, headers);
            if (response && response["success"] === true) {
                console.log(response["data"]);
                // setAllCategories([...allCategories, response["data"]]);
                await getAllAppMedia(token)
                return true;
            } else {
                console.log(response["message"], response.message);
                return false;

            }
        } catch (error) {
            console.error("Error during API call:", error);
            return false;

        }
    };


    const deleteAllAppMedia = async (token, imagesIds) => {
        try {
            const headers = jsonHeader(token);
      
            const response = await ApiTemplate("post", "/api/v1/remove/app/media", JSON.stringify({"id":imagesIds}), headers);
            if (response && response["success"] === true) {
              
              await getAllAppMedia(token);
              return true;
            } else {
              console.log(response["message"], response.message);
              return false;
      
            }
          } catch (error) {
            console.error("Error during API call:", error);
            return false;
      
          }
    };

   

    return (
        <AppMediaContext.Provider
            value={{
                allAppMedia,
                getAllAppMedia,
                addAllAppMedia,
                deleteAllAppMedia,

            }}
        >
            {children}
        </AppMediaContext.Provider>
    );
};

export const useAppMediaContext = () => {
    const appMediaContextValue = useContext(AppMediaContext);
    return appMediaContextValue;
};
